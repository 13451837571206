import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  fixtures: [],
}

const fixturesSlice = createSlice({
  name: 'fixture',
  initialState,
  reducers: {
    fetchFixtures(state, action) {},
    fetchFixturesSuccess(state, action) {
      state.fixtures = action.payload;
    },

    updateFixture(state, action){},
    updateFixtureSuccess(state, action){
      const findIndex = state.fixtures.findIndex(item => item.footballId === action.payload?.data?.footballId);

      if(findIndex > -1){

        if(action.payload?.data?.isHot !== undefined){
          state.fixtures[findIndex].isHot = action.payload?.data?.isHot;
        }
        
        if(action.payload?.data?.isHome !== undefined){
          state.fixtures[findIndex].isHome = action.payload?.data?.isHome;
        }

        if(action.payload?.data?.liveLinks !== undefined){
          state.fixtures[findIndex].liveLinks = action.payload?.data?.liveLinks;
        }

        if(action.payload?.data?.highlights !== undefined){
          state.fixtures[findIndex].highlights = action.payload?.data?.highlights;
        }

        state.fixtures = [...state.fixtures];
      }
    },

    addFixture(state, action){},
    addFixtureSuccess(state, action){
      state.fixtures = [...state.fixtures, action.payload];
    }
  },
});

// Actions
export const fixturesAction = fixturesSlice.actions;

// Reducer
const fixturesReducer = fixturesSlice.reducer;
export default fixturesReducer;