// // ** Redux Imports
// import rootReducer from './rootReducer'
// import { configureStore } from '@reduxjs/toolkit'

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: getDefaultMiddleware => {
//     return getDefaultMiddleware({
//       serializableCheck: false
//     })
//   }
// })

// export { store }


// ** Redux Imports
import rootReducer from "./rootReducer";
import { configureStore } from "@reduxjs/toolkit";
import createSagaMiddleware from "redux-saga";

import rootSaga from "../sagas/rootSaga";

const sagaMiddleware = createSagaMiddleware();

const store = configureStore({
  reducer: rootReducer,
  // Add saga to toolkit
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false
    }).concat(sagaMiddleware),
    
  devTools: process.env.NODE_ENV !== "production",
});

// Add root  saga
sagaMiddleware.run(rootSaga);

export { store };
