import { gql } from '@apollo/client';

export const FETCH_SCHEDULES = gql`
  query schedules($inputs: InputSchedule){
    schedules(
      inputs: $inputs
    ) {
      _id
      date
      events { 
        name 
        time 
        sport
        channels
        tournament
      }
      sports {
        _id
        scheduleId 
        eventsIndex
        nameEdit
        contents
        timeEnd
        isHot
        isHome
        liveLinks { lang, link, linkIframe, iframe }
        highlights { lang, link, linkIframe, iframe }
        replayDownloadLink
        replayLink
        replayDownloaded
        thumbnailReplay
        thumbnailsReplay
        isHideReplay
        iframe
        addManual
        orderBy
      }
    }
  }
`;
