import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import { useQuery } from "react-query";
import toast from "react-hot-toast";

import { fetchFixturesApi, updateFixtureApi, addFixtureApi } from "../api";
import { fixturesAction } from "../redux/slices/fixtureSlice";
import { LeaguesAction } from "../redux/slices/leagueSlice";

function* fetchFixturesSaga(action) {
  try {
    const res = yield call(fetchFixturesApi, { params: action.payload });

    if (res?.data) {
      yield put(fixturesAction.fetchFixturesSuccess(res?.data?.fixtures));
    }
  } catch (error) {
    console.log(error.message);
    toast.error("Get data errors !");
  }
}

function* updateFixtureSaga(action) {
  try {
    const res = yield call(updateFixtureApi, { params: action.payload });

    if (res?.data) {
      yield put(fixturesAction.updateFixtureSuccess(res?.data?.updateFixture));
      toast.success("Updated success !");
    } else {
      toast.error("Updated errors !");
    }
  } catch (error) {
    toast.error("Updated errors !");
  }
}


function* addFixtureSaga(action) {
  try {
    const res = yield call(addFixtureApi, { params: action.payload });

    const { data, ok, error } = res?.data?.addFixture;

    if (ok) {
      yield put(fixturesAction.addFixtureSuccess(data));
      toast.success("Add fixture success !");
    } else {
      toast.error(error);
    }
  } catch (error) {
    toast.error("Add errors !");
  }
}

export default function* reportSaga() {
  yield takeEvery(fixturesAction.fetchFixtures.type, fetchFixturesSaga);
  yield takeLatest(fixturesAction.updateFixture.type, updateFixtureSaga);
  yield takeLatest(fixturesAction.addFixture.type, addFixtureSaga);
}
