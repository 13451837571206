import { apolloClient } from "../graphql/apolloClient";
import { FETCH_FIXTURES, UPDATE_FIXTURE, ADD_FIXTURE } from "../graphql/fixtures";

export function fetchFixturesApi({token, params}) {
  return apolloClient.query({
    query: FETCH_FIXTURES,
    variables: params,
  });
}

export function updateFixtureApi({token, params}) {
  return apolloClient.mutate({
    mutation: UPDATE_FIXTURE,
    variables: params
  });
}

export function addFixtureApi({token, params}) {
  return apolloClient.mutate({
    mutation: ADD_FIXTURE,
    variables: params
  });
}