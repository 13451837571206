// ** Reducers Imports
import navbar from './navbar'
import layout from './layout'
import auth from './authentication'
import users from '@src/views/apps/user/store'
import kanban from '@src/views/apps/kanban/store'
import ecommerce from '@src/views/apps/ecommerce/store'
import dataTables from '@src/views/tables/data-tables/store'
import permissions from '@src/views/apps/roles-permissions/store'

// Hien

import bookListReducer  from "./slices/adminUserSlice";
import userListReducer from "./slices/userSlide";
import matchDateListReducer from "./slices/matchesDateSlice";
import competitionListReducer from "./slices/competitionSlice";
import teamListReducer from "./slices/teamSlice";
import leaguesReducer from "./slices/leagueSlice";
import fixturesReducer from "./slices/fixtureSlice";
import generalReducer from "./slices/generalSlice";
import historyPlanReducer from "./slices/historyPlan";
import payments from "./slices/paymentSlice";
import schedules from "./slices/scheduleSlice";
import sportsNews from "./slices/sportsNewsSlice";
import accessPages from "./slices/accessPageSlice";

const rootReducer = {
  auth,
  kanban,
  users,
  navbar,
  layout,
  ecommerce,
  dataTables,
  permissions,
  books: bookListReducer,
  user: userListReducer,
  matchesDate: matchDateListReducer,
  competitions: competitionListReducer,
  teams: teamListReducer,
  leagues: leaguesReducer,
  fixtures: fixturesReducer,
  generals: generalReducer,
  historyPlan: historyPlanReducer,
  payments,
  schedules,
  sportsNews,
  accessPages
}

export default rootReducer
