import { all } from 'redux-saga/effects';

import userAdminSaga from './userAdmin';
import userSaga from './userSaga';
import matchesDateSaga from './matchesDateSaga';
import competitionSaga from './competitionSaga';
import teamsSaga from './teamsSaga';
import leaguesSaga from './leaguesSaga';
import fixturesSaga from './fixturesSaga';
import generalSaga from './generalSaga';
import historyPlanSaga from './historyPlan';
import paymentSaga from './paymentSaga';
import scheduleSaga from './scheduleSaga';
import sportsNewsSaga from './sportsNewsSaga';
import accessPageSaga from './accessPageSaga';

export default function* rootSaga() {
  yield all([
    // userAdminSaga(),
    userSaga(),
    matchesDateSaga(),
    competitionSaga(),
    teamsSaga(),
    leaguesSaga(),
    fixturesSaga(),
    generalSaga(),
    historyPlanSaga(),
    paymentSaga(),
    scheduleSaga(),
    sportsNewsSaga(),
    accessPageSaga()
  ]);
}
