import { gql } from '@apollo/client';

// export const FETCH_PAYMENTS = gql`
//   query payments($limit: Int) {
//     payments (limit: $limit){
//       id
//       account
//       apiToken
//       paymentType
//       amount
//       selected
//       isNew
//       note
//       times
//       status
//     }
//   }
// `;

// export const FETCH_PAYMENTS_BY_ID = gql`
//   query payment($id: ID!) {
//     payment(id: $id){
//       id
//       account
//       apiToken
//       paymentType
//       amount
//       selected
//       isNew
//       times
//       note
//       status
//     }
//   }
// `;

export const CREATE_SPORT = gql`
  mutation createSport($inputs: InputSport) {
    createSport(inputs: $inputs) {
      data {
        _id
        scheduleId
        eventsIndex
        nameEdit
        contents
        timeEnd
        isHot
        isHome
        liveLinks {
          lang
          link
          linkIframe
          iframe
        }
        highlights {
          lang
          link
          linkIframe
          iframe
        }
        iframe
        addManual
        replayDownloadLink
        replayLink
        replayDownloaded
        thumbnailReplay
        isHideReplay
        orderBy
      }
      ok
      error
    }
  }
`;

// export const UPDATE_PAYMENT = gql`
//   mutation updatePayment($id: ID!, $input: InputPayment) {
//     updatePayment(id: $id, input: $input) {
//       data {
//         id
//         account
//         apiToken
//         paymentType
//         amount
//         selected
//         isNew
//         times
//         note
//         status
//       }
//       ok
//       error
//     }
//   }
// `;

// export const DELETE_PAYMENT = gql`
//   mutation deletePayment($id: ID!) {
//     deletePayment(id: $id) {
//       data {
//         id
//         account
//         apiToken
//         paymentType
//         amount
//         selected
//         isNew
//         times
//         status
//       }
//       ok
//       error
//     }
//   }
// `;
