import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accessPages: null
}

const accessPagesSlice = createSlice({
  name: 'accessPages',
  initialState,
  reducers: {
    fetchAccessPages(state, action) {},
    fetchAccessPagesSuccess(state, action) {
      state.accessPages = action.payload;
    },

    // updateGeneral(state, action){},
    // updateGeneralSuccess(state, action){
    //   state.general = action.payload;
    // }
  },
});

// Actions
export const { 
  fetchAccessPages,
  fetchAccessPagesSuccess
 } = accessPagesSlice.actions;

// Reducer
const accessPagesReducer = accessPagesSlice.reducer;
export default accessPagesReducer;