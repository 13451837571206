import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sportsNews: [],
  sportNews: [],
};

const SportsNewsSlice = createSlice({
  name: 'sports-news',
  initialState,
  reducers: {
    fetchSportsNewsList(state, action) {},

    fetchSportsNewsListSuccess(state, action) {
      state.sportsNews = action.payload;
    },

    fetchSportsNewsById(state, action) {
      state.sportNews = null;
    },

    fetchSportsNewsByIdSuccess(state, action) {
      const data = action.payload;
      const findIndex = state.sportsNews.findIndex(
        (item) => item?._id === data?._id
      );
      if (findIndex > -1) {
        state.sportsNews = [...state.sportsNews, data];
      }
      state.sportNews = action.payload;
    },

    createSportNews(state, action) {},
    createSportNewsSuccess(state, action) {
      const data = action.payload;
      const findIndex = state.sportsNews.findIndex(
        (item) => item?._id === data?._id
      );
      if (findIndex === -1) {
        state.sportsNews = [...state.sportsNews, data];
      }
    },

    updateSportNews(state, action) {},
    updateSportNewsSuccess(state, action) {
      const data = action.payload;
      const findIndex = state.sportsNews.findIndex(
        (item) => item?._id === data?._id
      );
      if (findIndex > -1) {
        state.sportsNews[findIndex] = { ...data };
      }
    },

    deleteSportNews(state, action) {},
    deleteSportNewsSuccess(state, action) {
      const data = action.payload;
      const filterData = state.sportsNews.filter(
        (item) => item?._id !== data?._id
      );
      state.sportsNews = filterData;
    },
  },
});

// Actions
export const {
  fetchSportsNewsList,
  fetchSportsNewsListSuccess,
  createSportNews,
  createSportNewsSuccess,
  updateSportNews,
  updateSportNewsSuccess,
  deleteSportNews,
  deleteSportNewsSuccess,
  fetchSportsNewsByIdSuccess,
  fetchSportsNewsById,
} = SportsNewsSlice.actions;

// Reducer
const SportsNewsReducer = SportsNewsSlice.reducer;
export default SportsNewsReducer;
