import { gql } from "@apollo/client";

export const FETCH_SPORTS_NEWS = gql`
  query sportsNews($limit: Int) {
    sportsNews(limit: $limit){
      _id
      title
      description
      contents
      sport
      orderBy
      status
    }
  }
`;

export const FETCH_SPORTS_NEWS_BY_ID = gql`
  query sportNews($id: ID!) {
    sportNews(id: $id){
      _id
      title
      description
      contents
      sport
      orderBy
      status
    }
  }
`;


export const CREATE_SPORTS_NEWS = gql`
  mutation createSportNews($inputs: InputSportNews) {
    createSportNews(inputs: $inputs){
      data { 
        _id,
        title
        description
        contents
        sport
        orderBy
        status
      }
      ok
      error
    }
  }
`;

export const UPDATE_SPORTS_NEWS= gql`
  mutation updateSportNews($id: ID!, $inputs: InputSportNews) {
    updateSportNews(id: $id, inputs: $inputs){
      data { 
        _id,
        title
        description
        contents
        sport
        orderBy
        status
      }
      ok
      error
    }
  }
`;
export const DELETE_SPORTS_NEWS = gql`
  mutation deleteSportNews($id: ID!) {
    deleteSportNews(id: $id){
      data { 
        _id,
        title
        description
        contents
        sport
        orderBy
        status
      }
      ok
      error
    }
  }
`;
