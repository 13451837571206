
import { gql } from "@apollo/client";

export const FETCH_ACCESS_PAGE = gql`
  query accessPages($startDate: DateTime, $endDate: DateTime, $type: String, $get_type: String) {
    accessPages(startDate: $startDate, endDate: $endDate, type: $type, get_type: $get_type){
      _id
      sum
      sumPay
      refPage
      sumRegistered
      domain
      payment
      email
      paymentDate
      user { fullName, email }
      ipv4,
      updatedAt,
      createdAt
    }
  }
`;
