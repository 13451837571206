import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import toast from 'react-hot-toast';

import { fetchScheduleApi, createSportApi } from '../api';

import {
  fetchScheduleList,
  fetchScheduleListSuccess,
  createSport,
  createSportSuccess
} from '../redux/slices/scheduleSlice';

import { SPORTS } from '../constants';

function* fetchScheduleSaga(action) {
  try {
    const res = yield call(fetchScheduleApi, { params: action.payload });
    if (res?.data) {
      const data = res?.data?.schedules?.map((item) => {
        const eventsIndex = item?.events?.map((i, idx) => {
          const sportLive = item?.sports.find(
            (s) => item?._id === s?.scheduleId && idx === s?.eventsIndex
          );
          if (sportLive) {
            return {
              ...i,
              idx,
              sportLive,
              _id: item?._id,
              
            };
          }
          return {
            ...i,
            idx,
            sportLive: null,
            _id: item?._id,
          };
        });
        return {
          ...item,
          events: eventsIndex,
        };
      });

      let events = [];

      const eventData = data.map((item) => {
        return item?.events?.map((i) => {
          return i;
        });
      });

      for(const i of eventData){
        events = [...events, ...i]
        // for(const j of i){
        //   events.push(j)
        // }
      }

      // console.log("events", events)
      const filterSport = events.filter(i => SPORTS.includes(i?.sport));
      yield put(fetchScheduleListSuccess(filterSport));
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

function* createSportSaga(action) {
  try {
    const res = yield call(createSportApi, { params: action.payload });

    const { data, ok, error } = res?.data?.createSport;

    if (ok) {
      yield put(createSportSuccess(data));
      toast.success("Updated success !");
    } else {
      toast.error(error);
    }
  } catch (error) {
    toast.error(error?.message);
  }
}


export default function* reportSaga() {
  yield takeEvery(fetchScheduleList.type, fetchScheduleSaga);
  yield takeLatest(createSport.type, createSportSaga);
}
