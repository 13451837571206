import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  schedules: [],
  events: []
}

const SchedulesSlice = createSlice({
  name: 'schedules',
  initialState,
  reducers: {
    fetchScheduleList(state, action) {},

    fetchScheduleListSuccess(state, action) {
      state.events = action.payload;
    },

    createSport(state, action) {

    },

    createSportSuccess(state, action) {
      const data = action.payload;
      const findIndex = state.events.findIndex(item => item?._id === data?.scheduleId && item?.idx === data?.eventsIndex);
      if(findIndex > -1){
        state.events[findIndex].sportLive = data;
        state.events = [...state.events];
      }
    }

  },
});

// Actions
export const {
  fetchScheduleList,
  fetchScheduleListSuccess,
  createSport,
  createSportSuccess
} = SchedulesSlice.actions;

// Reducer
const SchedulesReducer = SchedulesSlice.reducer;
export default SchedulesReducer;