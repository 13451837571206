import { apolloClient } from "../graphql/apolloClient";

import {
  FETCH_SPORTS_NEWS,
  FETCH_SPORTS_NEWS_BY_ID,
  CREATE_SPORTS_NEWS,
  UPDATE_SPORTS_NEWS,
  DELETE_SPORTS_NEWS
} from "../graphql/sportsNews";

export function fetchSportsNewsApi({token, params}) {
  return apolloClient.query({
    query: FETCH_SPORTS_NEWS,
    variables: params
  });
}
export function fetchSportsNewsByIdApi({ params }) {
  return apolloClient.query({
    query: FETCH_SPORTS_NEWS_BY_ID,
    variables: params,
  });
}

export function createSportsNewsApi({ token, params }) {
  return apolloClient.mutate({
    mutation: CREATE_SPORTS_NEWS,
    variables: params,
  });
}

export function updateSportsNewsApi({ token, params }) {
  return apolloClient.mutate({
    mutation: UPDATE_SPORTS_NEWS,
    variables: params,
  });
}

export function deleteSportsNewsApi({ token, params }) {
  return apolloClient.mutate({
    mutation: DELETE_SPORTS_NEWS,
    variables: params,
  });
}
