import { gql } from "@apollo/client";

export const FETCH_FIXTURES = gql`
  query fixtures(
    $limit: Int
    $startDate: String
    $endDate: String
    $leagueId: String
    $status: String
  ) {
    fixtures(
      limit: $limit
      startDate: $startDate
      endDate: $endDate
      leagueId: $leagueId
      status: $status
    ) {
      id
      footballId
      leagueFootballId
      leagueId
      homeFootballId
      awayFootballId
      referee
      timezone
      date
      timestamp
      venue {
        name
        city
      }
      status {
        long
        short
        elapsed
      }
      league {
        id
        name
        footballId
        type
        logo
        logoLink
      }
      teams {
        home {
          id
          name
          logo
          winner
        }
        away {
          id
          name
          logo
          winner
        }
      }
      goals {
        home
        away
      }
      score {
        halftime {
          home
          away
        }
        fulltime {
          home
          away
        }
        extratime {
          home
          away
        }
        penalty {
          home
          away
        }
      }
      liveLinks {
        lang
        link
        linkIframe
        iframe
      }
      highlights {
        lang
        link
        iframe
      }
      orderBy
      isHot
      isHome
    }
  }
`;

export const ADD_FIXTURE = gql`
  mutation addFixture(
    $footballId: Int
    $isHot: Boolean
    $isHome: Boolean
    $leagueId: String
    $leagueFootballId: Int,
    $homeFootballId: Int,
    $awayFootballId: Int,
    $referee: String,
    $timezone: String,
    $date: DateTime,
    $timestamp: DateTime,
    $periods: Object,
    $events: [EventInput]
    $venue: Object,
    $status: Object,
    $league: Object,
    $teams: Object,
    $goals: Object,
    $score: Object,
    $addManual: Boolean
  ) {
    addFixture(
    footballId: $footballId
    isHot: $isHot
    isHome: $isHome
    leagueId: $leagueId
    leagueFootballId: $leagueFootballId,
    homeFootballId: $homeFootballId,
    awayFootballId: $awayFootballId,
    referee: $referee,
    timezone: $timezone,
    date: $date,
    timestamp: $timestamp,
    periods: $periods,
    venue: $venue,
    status: $status,
    league: $league,
    teams: $teams,
    goals: $goals,
    score: $score,
    events: $events,
    addManual: $addManual
    ) {
      data {
        id
        footballId
        leagueFootballId
        leagueId
        homeFootballId
        awayFootballId
        referee
        timezone
        date
        timestamp
        venue {
          name
          city
        }
        status {
          long
          short
          elapsed
        }
        league {
          id
          name
          footballId
          type
          logo
          logoLink
        }
        teams {
          home {
            id
            name
            logo
            winner
          }
          away {
            id
            name
            logo
            winner
          }
        }
        goals {
          home
          away
        }
        score {
          halftime {
            home
            away
          }
          fulltime {
            home
            away
          }
          extratime {
            home
            away
          }
          penalty {
            home
            away
          }
        }
        liveLinks {
          lang
          link
          linkIframe
          iframe
        }
        highlights {
          lang
          link
          iframe
        }
        orderBy
        isHot
        isHome
        addManual
      }
      ok
      error
    }
  }
`;

export const UPDATE_FIXTURE = gql`
  mutation updateFixture(
    $footballId: Int
    $isHot: Boolean
    $isHome: Boolean
    $leagueId: String
    $liveLinks: String
    $highlights: String
  ) {
    updateFixture(
      footballId: $footballId
      isHot: $isHot
      isHome: $isHome
      liveLinks: $liveLinks
      leagueId: $leagueId
      highlights: $highlights
    ) {
      data {
        footballId
        isHot
        isHome
        liveLinks {
          lang
          link
          linkIframe
          iframe
        }
        highlights {
          lang
          link
          iframe
        }
      }
      ok
      error
    }
  }
`;
