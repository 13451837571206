import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
// import { useQuery } from "react-query";
import toast from "react-hot-toast";

import { fetchGeneralApi, updateGeneralApi  } from "../api";
import { fetchGeneral, fetchGeneralsSuccess, updateGeneral, updateGeneralSuccess } from "../redux/slices/generalSlice";

function* fetchGeneralSaga(action) {
  try {
    const res = yield call(fetchGeneralApi, { params: action.payload });

    if (res?.data?.generals?.length > 0) {
      yield put(fetchGeneralsSuccess(res?.data?.generals[0]));
    }
  } catch (error) {
    console.log(error.message);
    toast.error("Get data errors !");
  }
}

function* updateGeneralSaga(action) {
  try {
    const res = yield call(updateGeneralApi, { params: action.payload });

    if (res?.data?.updateGeneral?.ok) {
      yield put(updateGeneralSuccess(res?.data?.updateGeneral?.data));
      toast.success("Updated success !");
    } else {
      toast.error("Updated errors !");
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

export default function* reportSaga() {
  yield takeEvery(fetchGeneral.type, fetchGeneralSaga);
  yield takeLatest(updateGeneral.type, updateGeneralSaga);
}
