import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import toast from "react-hot-toast";

import {
  fetchSportsNewsApi,
  createSportsNewsApi,
  deleteSportsNewsApi,
  updateSportsNewsApi,
  fetchSportsNewsByIdApi
} from "../api";

import { 
  fetchSportsNewsList,
  fetchSportsNewsListSuccess,
  createSportNews,
  createSportNewsSuccess,
  updateSportNews,
  updateSportNewsSuccess,
  fetchSportsNewsById,
  fetchSportsNewsByIdSuccess,
  deleteSportNews,
  deleteSportNewsSuccess
 } from "../redux/slices/sportsNewsSlice";

function* fetchSportsNewsSaga(action) {
  try {
    const res = yield call(fetchSportsNewsApi, { params: action.payload });
    yield put((fetchSportsNewsListSuccess(res?.data?.sportsNews)));
  } catch (error) {
    toast.error("Get errors !");
  }
}

function* fetchSportsNewsByIdSaga(action) {
  try {
    const res = yield call(fetchSportsNewsByIdApi, { params: action.payload });
    yield put(fetchSportsNewsByIdSuccess(res?.data?.sportNews));
  } catch (error) {
    toast.error("Get errors !");
  }
}

function* createSportsNewsSaga(action) {
  try {
    const res = yield call(createSportsNewsApi, { params: action.payload });

    const { data, errors } = res;
    
    if (data?.createSportNews?.ok) {
      toast.success("Created success");
      yield put(createSportNewsSuccess(data?.createSportNews?.data));
    } else {
      if (data.createSportNews?.error) {
        toast.error(`${data.createSportNews?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}


function* deleteSportsNewsSaga(action) {
  try {
    const res = yield call(deleteSportsNewsApi, { params: action.payload });

    const { data, errors } = res;
    
    if (data.deleteSportNews?.ok) {
      toast.success("Deleted Success");
      yield put(deleteSportNewsSuccess(data?.deleteSportNews?.data));

    } else {
      if (data.deleteSportNews?.error) {
        toast.error(`${data.deleteSportNews?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}
function* updateSportsNewsSaga(action) {
  try {
    const res = yield call(updateSportsNewsApi, { params: action.payload });

    const { data, errors } = res;
    
    if (data.updateSportNews?.ok) {
      toast.success("Updated Success");
      yield put(updateSportNewsSuccess(data?.updateSportNews?.data));
    } else {
      if (data.updateSportNews?.error) {
        toast.error(`${data.updateSportNews?.error}`);
      } else {
        toast.error(`${errors[0].message}`);
      }
    }
  } catch (error) {
    toast.error(error?.message);
  }
}

export default function* reportSaga() {
  yield takeEvery(fetchSportsNewsList.type, fetchSportsNewsSaga);
  yield takeEvery(fetchSportsNewsById.type, fetchSportsNewsByIdSaga);
  yield takeLatest(createSportNews.type, createSportsNewsSaga);
  yield takeLatest(deleteSportNews.type, deleteSportsNewsSaga);
  yield takeLatest(updateSportNews.type, updateSportsNewsSaga);
}
