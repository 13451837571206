import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import toast from "react-hot-toast";

import { fetchAccessPageApi } from "../api";
import { fetchAccessPages, fetchAccessPagesSuccess } from "../redux/slices/accessPageSlice";

function* fetchAccessPageSaga(action) {
  try {
    const res = yield call(fetchAccessPageApi, { params: action.payload });

    const { accessPages } = res.data;

    if (accessPages) {
      yield put(fetchAccessPagesSuccess(accessPages));
    }
  } catch (error) {
    console.log(error.message);
    toast.error("Get data errors !");
  }
}

// function* updateGeneralSaga(action) {
//   try {
//     const res = yield call(updateGeneralApi, { params: action.payload });

//     if (res?.data?.updateGeneral?.ok) {
//       yield put(updateGeneralSuccess(res?.data?.updateGeneral?.data));
//       toast.success("Updated success !");
//     } else {
//       toast.error("Updated errors !");
//     }
//   } catch (error) {
//     toast.error(error?.message);
//   }
// }

export default function* reportSaga() {
  yield takeEvery(fetchAccessPages.type, fetchAccessPageSaga);
  // yield takeLatest(updateGeneral.type, updateGeneralSaga);
}
