import { apolloClient } from "../graphql/apolloClient";

import { FETCH_SCHEDULES } from "../graphql/schedule";

export function fetchScheduleApi({token, params}) {
  return apolloClient.query({
    query: FETCH_SCHEDULES,
    variables: params
  });
}
